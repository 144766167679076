//import { useState } from "react";
import Logo from "../../styles/img/header.svg";
const Header = ({ responseScan, tenantColor, tenantImage, tenantName }) => {
  return (
    <>
      <section className="flex flex-col justify-center items-center mt-1">
        {tenantImage ? (
          <img src={tenantImage} alt="" className="w-4/12" />
        ) : (
          <img src={Logo} alt="" className="w-4/12" />
        )}
        {tenantName ? (
          <div className=" mt-1">
            <p className="text-sm text-center font-semibold">{tenantName}</p>

            {tenantImage && (
              <p className="text-xs text-center">
                powered by{" "}
                <strong>
                  PAL
                  <span className="text-[#6A2ABD] text-xs text-center">ID</span>
                  IN
                </strong>
              </p>
            )}
          </div>
        ) : (
          <p className=""></p>
        )}
      </section>
    </>
  );
};

export default Header;
