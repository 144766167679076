//react/redux
import React, { useEffect, useState } from "react";
import { store } from "../../../services/api/store";
import { scanToken } from "../../../services/api/slices/paladin";
import { getInstance } from "../../../services/api/slices/Assure";
import * as Sentry from "@sentry/react";

//components
import AutoCapture from "./AutoCapture";
import ManualCapture from "./ManualCapture";

const AcuantReactCamera = ({ tenantColor, tenantImage, tenantName }) => {
  const [responseScan, setResponseScan] = useState();
  const [instance, setInstance] = useState();
  const { dispatch } = store;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("token");

  const queryData = async () => {
    var data = { token };
    try {
      const response = await dispatch(scanToken({ data }));
      setResponseScan(response?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  const queryInstance = async (responseScan) => {
    try {
      var data = { responseScan };

      const instance = await dispatch(
        getInstance({
          data,
        })
      );
      if (instance?.payload || !instance?.payload?.error) {
        setInstance(instance?.payload);
        Sentry.addBreadcrumb({
          message: "Instance set (AcuantReactCamera)",
          category: "action",
          data: {
            token: token,
            responseScan: responseScan,
          },
        });
      } else {
        window.alert("Error! reload page or contact us");
        Sentry.addBreadcrumb({
          message: "Error loading Acuant Instance (AcuantReactCamera)",
          category: "action",
        });
        Sentry.captureException(instance?.payload?.error);
      }
    } catch (error) {
      Sentry.addBreadcrumb({
        message: "Error processsing instance (AcuantReactCamera)",
        category: "action",
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    queryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseScan !== undefined && !responseScan !== null) {
      queryInstance(responseScan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseScan]);

  return (
    <>
      {window.AcuantCamera.isCameraSupported ? (
        <AutoCapture
          instance={instance}
          responseScan={responseScan}
          tenantColor={tenantColor}
          tenantImage={tenantImage}
          tenantName={tenantName}
        />
      ) : (
        <ManualCapture
          instance={instance}
          responseScan={responseScan}
          tenantColor={tenantColor}
          tenantImage={tenantImage}
          tenantName={tenantName}
        />
      )}
    </>
  );
};

export default AcuantReactCamera;
