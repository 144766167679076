import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    instance: {},
    PostImage: {},
    ReplaceImage: {},
    error: null,
    loading: true,
};

export const getInstance = createAsyncThunk(
    "Doc Instance",
    async ({ data }, thunkAPI) => {
        let pjson = require("../../../../package.json");
        let url = `${process.env.REACT_APP_ID_ENDPOINT}/AssureIDService/Document/Instance`;
        let body = {
            AuthenticationSensitivity: 0,
            ClassificationMode: 0,
            Device: {
                HasContactlessChipReader: false,
                HasMagneticStripeReader: false,
                SerialNumber: "JavaScriptWebSDK " + pjson.version,
                Type: {
                    Manufacturer: "xxx",
                    Model: "xxx",
                    SensorType: 3,
                },
            },
            ImageCroppingExpectedSize: 0,
            ImageCroppingMode: 0,
            ManualDocumentType: null,
            ProcessMode: 0,
            SubscriptionId:
                data?.responseScan?.ScanToken?.AcuantApiSubscriptionId ||
                process.env.REACT_APP_SUBSCRIPTION_ID,
        };
        let AUTH_TOKEN = btoa(
            `${data?.responseScan?.ScanToken?.AcuantApiSubscriptionId ||
            process.env.REACT_APP_SUBSCRIPTION_ID
            };${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
        );
        let headers = {
            Authorization: `Basic ${AUTH_TOKEN}`,
        };

        try {
            const response = await axios.post(url, body, { headers });
            if (response.data) {
                return {
                    instance: response.data,
                };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const postImage = createAsyncThunk(
    "Post Image",
    async ({ data }, thunkAPI) => {
        let url = `${process.env.REACT_APP_ID_ENDPOINT}/AssureIDService/Document/${data?.instance}/Image?side=${data?.side}&light=0&metrics=true`;

        let AUTH_TOKEN = btoa(
            `${data?.AcuantApiSubscriptionId || process.env.REACT_APP_SUBSCRIPTION_ID
            };${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
        );
        let headers = {
            Authorization: `Basic ${AUTH_TOKEN}`,
        };
        try {
            const response = await axios.post(url, data?.image, {
                headers,
            });
            if (response.data) {
                return {
                    PostImage: response.data,
                };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const replaceImage = createAsyncThunk(
    "Replace Image",
    async ({ data }, thunkAPI) => {
        let url = `${process.env.REACT_APP_ID_ENDPOINT}/AssureIDService/Document/${data?.instanceID}/Image?side=${data?.side}&light=0&metrics=true`;
        let body = {};
        let AUTH_TOKEN = btoa(
            `${process.env.REACT_APP_SUBSCRIPTION_ID};${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
        );
        let headers = {
            Authorization: `Basic ${AUTH_TOKEN}`,
        };
        try {
            const response = await axios.put(url, body, {
                headers,
            });
            if (response.data) {
                return {
                    ReplaceImage: response.data,
                };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const assureSlice = createSlice({
    name: "Assure ID Service",
    initialState,
    reducers: {
        reset: (state) => {
            return initialState;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getInstance.fulfilled, (state, action) => {
            state.instance = action.payload?.instance;
        });
        builder.addCase(getInstance.rejected, (state, action) => {
            state.instance = initialState.instance;
        });
        builder.addCase(postImage.fulfilled, (state, action) => {
            state.PostImage = action.payload?.PostImage;
        });
        builder.addCase(postImage.rejected, (state, action) => {
            state.PostImage = initialState.PostImage;
        });
        builder.addCase(replaceImage.fulfilled, (state, action) => {
            state.ReplaceImage = action.payload?.ReplaceImage;
        });
        builder.addCase(replaceImage.rejected, (state, action) => {
            state.ReplaceImage = initialState.ReplaceImage;
        });
    },
});

export const { reset } = assureSlice.actions;
