import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  ScanToken: {},
  ScanProcess: {},
  error: null,
  loading: true,
};

export const scanToken = createAsyncThunk(
  "ScanToken",
  async ({ data }, thunkAPI) => {
    const headers = {
      "api-version": "v1",
      "Ocp-Apim-Subscription-Key": "08ed34ad38d34939ba9888480eba417d",
    };
    let url = `${process.env.REACT_APP_PALIDIN_ENDPOINT}scan/custom/token?token=${data?.token}`;
    try {
      const response = await axios.post(url, null, { headers });
      if (response.data) {
        return {
          ScanToken: response.data,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const scanProcess = createAsyncThunk(
  "ScanProcess",
  async ({ data }, thunkAPI) => {
    const headers = {
      "api-version": "v1",
      'Content-Type': 'application/json',
      "Ocp-Apim-Subscription-Key": "08ed34ad38d34939ba9888480eba417d",
    };

    console.log('data', data);

    let url = `${process.env.REACT_APP_PALIDIN_ENDPOINT}scan/process?accessToken=${data?.accessToken}&invitationId=${data?.invitationId}&acuantTransactionId=${data?.acuantTransactionId}`;

    const payload = {
      selfie: data?.selfie  // This is the base64 image you want to send
    };


    try {
      const response = await axios.post(url, payload, { headers });
      if (response.data) {
        return {
          ScanProcess: response.data,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const paladinSlice = createSlice({
  name: "Paladin",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(scanToken.fulfilled, (state, action) => {
      state.ScanToken = action.payload?.ScanToken;
    });
    builder.addCase(scanToken.rejected, (state, action) => {
      state.ScanToken = initialState.ScanToken;
    });
    builder.addCase(scanProcess.fulfilled, (state, action) => {
      state.ScanProcess = action.payload?.ScanProcess;
    });
    builder.addCase(scanProcess.rejected, (state, action) => {
      state.ScanProcess = initialState.ScanProcess;
    });
  },
});

export const { reset } = paladinSlice.actions;
