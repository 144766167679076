//react/redux
import { AiFillCheckCircle } from "react-icons/ai";
import * as Sentry from "@sentry/react";

//components
import Header from "../Common/header";
const Success = ({ tenantColor, tenantImage, tenantName, responseScan }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    Sentry.addBreadcrumb({
        message: "Process completed",
        category: "action",
        data: {
            token: token,
        },
    });
    Sentry.captureMessage("Process completed", "info");

    return (
        <>
            <Header
                responseScan={responseScan}
                tenantColor={tenantColor}
                tenantImage={tenantImage}
                tenantName={tenantName}
            />
            <section className="bg-white border rounded-lg border-[#DADADA] px-4 py-36 flex flex-col justify-center items-center gap-4 my-5">
                <AiFillCheckCircle size={150} className="text-[#1DB83F]" />
                <h2 className="text-2xl">Done!</h2>
                <p className="text-center text-sm">
                    The authentication process is now complete. We will contact you if we
                    have any questions about your authentication results.
                </p>
            </section>
        </>
    );
};

export default Success;
