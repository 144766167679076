//react-redux
import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

//components
import AcuantReactCamera from "../screens/AcuantReactCamera/index";
import Header from "../Common/header";

const Layout = ({ responseScan, tenantColor, tenantImage, tenantName }) => {
    const [next, setNext] = useState(false);

    return (
        <>
            <section className="container p-2 w-full">
                <>
                    {next ? (
                        <AcuantReactCamera
                            responseScan={responseScan}
                            tenantColor={tenantColor}
                            tenantImage={tenantImage}
                            tenantName={tenantName}
                        />
                    ) : (
                        <>
                            <section className="flex flex-col gap-2 justify-between">
                                <Header
                                    responseScan={responseScan}
                                    tenantColor={tenantColor}
                                    tenantImage={tenantImage}
                                    tenantName={tenantName}
                                />

                                <div className="">
                                    <h2 className="text-2xl text-center my-5">
                                        Confirm your identity
                                    </h2>
                                    <p className="text-sm text-center m-0 p-0">
                                        You’ll need to do the following
                                    </p>
                                </div>

                                <section className="bg-white border rounded-lg border-[#DADADA] px-4 py-5">
                                    <div className="flex justify-start items-center gap-3 mb-3">
                                        {/* Icon Card Front*/}
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="3"
                                                y="9"
                                                width="34"
                                                height="22"
                                                rx="3"
                                                fill="white"
                                                stroke={tenantColor}
                                                strokeWidth="2"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.9998 20C14.5777 20 15.8569 18.6568 15.8569 17C15.8569 15.3431 14.5777 14 12.9998 14C11.4218 14 10.1426 15.3431 10.1426 17C10.1426 18.6568 11.4218 20 12.9998 20ZM13 20C10.2386 20 8 22.2386 8 25C8 25.5523 8.44771 26 9 26H17C17.5523 26 18 25.5523 18 25C18 22.2386 15.7614 20 13 20ZM22 16H32V18H22V16ZM32 22H22V24H32V22Z"
                                                fill={tenantColor}
                                            />
                                        </svg>

                                        <p className="text-sm ">Scan of the front of the ID</p>
                                    </div>
                                    <div className="flex justify-start items-center gap-3 mb-3">
                                        {/* Icon Card Back*/}
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="3"
                                                y="9"
                                                width="34"
                                                height="22"
                                                rx="3"
                                                fill="white"
                                                stroke={tenantColor}
                                                strokeWidth="2"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8 15H32V17H8V15ZM8 19H32V21H8V19ZM32 23H8V25H32V23Z"
                                                fill={tenantColor}
                                            />
                                        </svg>

                                        <p className="text-sm">Scan of the back of the ID</p>
                                    </div>
                                    <div className="flex justify-start items-center gap-3 mb-3">
                                        {/* Icon Selfie*/}
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M32 6H29C28.4477 6 28 5.55228 28 5C28 4.44772 28.4477 4 29 4H32C34.2091 4 36 5.79086 36 8V11C36 11.5523 35.5523 12 35 12C34.4477 12 34 11.5523 34 11V8C34 6.89543 33.1046 6 32 6ZM5 12C5.55229 12 6 11.5523 6 11V8C6 6.89543 6.89543 6 8 6H11C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4H8C5.79086 4 4 5.79086 4 8V11C4 11.5523 4.44772 12 5 12ZM5 28C4.44772 28 4 28.4477 4 29V32C4 34.2091 5.79086 36 8 36H11C11.5523 36 12 35.5523 12 35C12 34.4477 11.5523 34 11 34H8C6.89543 34 6 33.1046 6 32V29C6 28.4477 5.55229 28 5 28ZM34 29C34 28.4477 34.4477 28 35 28C35.5523 28 36 28.4477 36 29V32C36 34.2091 34.2091 36 32 36H29C28.4477 36 28 35.5523 28 35C28 34.4477 28.4477 34 29 34H32C33.1046 34 34 33.1046 34 32V29Z"
                                                fill={tenantColor}
                                            />
                                            <path
                                                d="M29 20C29 24.9706 24.9706 29 20 29C15.0294 29 11 24.9706 11 20C11 15.0294 15.0294 11 20 11C24.9706 11 29 15.0294 29 20Z"
                                                fill="white"
                                                stroke={tenantColor}
                                                strokeWidth="2"
                                            />
                                            <path
                                                d="M10.4138 17.1434C11.643 13.0124 15.4696 10 19.9999 10C23.9789 10 27.4151 12.3239 29.0253 15.6886C27.8012 16.8133 26.1682 17.5 24.3749 17.5C21.6948 17.5 19.373 15.9665 18.2389 13.7291C17.2792 15.9477 15.0708 17.5 12.4999 17.5C11.7684 17.5 11.0662 17.3743 10.4138 17.1434Z"
                                                fill={tenantColor}
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M17.5 22C18.3284 22 19 21.3284 19 20.5C19 19.6716 18.3284 19 17.5 19C16.6716 19 16 19.6716 16 20.5C16 21.3284 16.6716 22 17.5 22ZM22.5 22C23.3284 22 24 21.3284 24 20.5C24 19.6716 23.3284 19 22.5 19C21.6716 19 21 19.6716 21 20.5C21 21.3284 21.6716 22 22.5 22Z"
                                                fill={tenantColor}
                                            />
                                        </svg>

                                        <p className="text-sm">Quick scan of your face</p>
                                    </div>
                                </section>
                                <p className="text-sm text-center m-0 p-0 opacity-80">
                                    Version 1.0.2
                                </p>
                                <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                                    <button
                                        className={`shadow-md `}
                                        style={{
                                            backgroundColor: tenantColor,
                                        }}
                                        onClick={() => {
                                            setNext(true);
                                        }}
                                    >
                                        Start <FiArrowRight size={20} />
                                    </button>
                                </div>
                            </section>
                        </>
                    )}
                </>
            </section>
        </>
    );
};

export default Layout;
