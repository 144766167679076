//react
import { AiOutlineLoading3Quarters } from "react-icons/ai";
//import { useLottie } from "lottie-react";
//import Loading from "../styles/Lottie/Loader.json";
//import Logo from "../styles/img/header.svg";

//component
//import Header from "../components/Common/header";

const RoundLoader = ({
  loading,
  responseScan,
  tenantColor,
  tenantImage,
  tenantName,
}) => {
  // const options = {
  //   animationData: Loading,
  //   loop: true,
  // };

  //const { View } = useLottie(options);

  return (
    <>
      {loading && (
        <div className="flex flex-col gap-5 items-center justify-center">
          {/* <img src={Logo} alt="logo" className="" /> */}
          <div className="w-3/6 p-0 flex items-center justify-center">
            <AiOutlineLoading3Quarters
              size={60}
              className={`animate-spin`}
              style={{
                color: "#6A2ABD",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default RoundLoader;
