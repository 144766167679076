//react/redux
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { store } from "../../services/api/store";
import { scanProcess } from "../../services/api/slices/paladin";
import { IoMdClose } from "react-icons/io";
import { BiSolidFace } from "react-icons/bi";
import * as Sentry from "@sentry/react";

//constants
import {
  ALERTS_STYLES_SELFIE,
  ALERTS_POSITION_SELFIE,
} from "../../constants/alerts";

//hooks
import AnalyzingLoader from "../../hooks/AnalyzingLoader";

//components
import Header from "../Common/header";
import Success from "./Success";

const CaptureSelfie = ({
  instance,
  responseScan,
  tenantColor,
  tenantImage,
  tenantName,
}) => {
  const [selfie, setSelfie] = useState("");
  const [responsePayload, setResponsePayload] = useState();
  const { dispatch } = store;
  const [loading, setLoading] = useState(false);
  const [toastId, setToastId] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("token");

  const openFrontCamara = () => {
    window.AcuantPassiveLiveness.start(
      faceCaptureCallback,
      faceDetectionStates
    );
  };

  const faceDetectionStates = {
    FACE_NOT_FOUND: "FACE NOT FOUND",
    TOO_MANY_FACES: "TOO MANY FACES",
    FACE_ANGLE_TOO_LARGE: "FACE ANGLE TOO LARGE",
    PROBABILITY_TOO_SMALL: "PROBABILITY TOO SMALL",
    FACE_TOO_SMALL: "FACE TOO SMALL",
    FACE_CLOSE_TO_BORDER: "TOO CLOSE TO THE FRAME",
  };

  const faceCaptureCallback = {
    onDetectorInitialized: () => {},
    onDetection: (text) => {},
    onOpened: () => {
      if (toastId === null || toastId === undefined) {
        setToastId(1);
        toast.success((t) => (
          <span>
            Please put your face in the <b> greeen circle</b>{" "}
            <button onClick={() => toast.dismiss(t.id)}>
              <IoMdClose size={15} />
            </button>
          </span>
        ));
      } else {
        toast.dismiss(toastId);
      }
    },
    onClosed: () => {
      toast.dismiss(toastId);
    },
    onError: (error) => {
      Sentry.addBreadcrumb({
        message: "Error capturing selfie (Capture Selfie)",
        category: "action",
      });
      Sentry.captureException(error);
    },
    onPhotoTaken: () => {
      if (toastId === null || toastId === undefined) {
        setToastId(1);
        toast.success((t) => (
          <span>
            Please touch the <b> greeen checkmark </b>{" "}
            <button onClick={() => toast.dismiss(t.id)}>
              <IoMdClose size={15} />
            </button>
          </span>
        ));
      } else {
        toast.dismiss(toastId);
      }
    },
    onPhotoRetake: () => {},
    onCaptured: (base64Image) => {
      setLoading(true);
      let base64Token = btoa(
        process.env.REACT_APP_USER_NAME + ":" + process.env.REACT_APP_PASSWORD
      );
      window.AcuantPassiveLiveness.getLiveness(
        {
          endpoint: process.env.REACT_APP_LIVENESS_ENDPOINT,
          token: base64Token,
          subscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID,
          image: base64Image,
        },
        (result) => {
          if (result) {
            setSelfie(base64Image);
            Sentry.addBreadcrumb({
              message: "Selfie captured",
              category: "action",
              data: {
                token: token,
              },
            });
          }
        }
      );
    },
  };

  const sendData = async () => {
    const data = {
      acuantTransactionId: instance,
      accessToken: responseScan?.ScanToken?.AccessToken,
      invitationId: responseScan?.ScanToken?.InvitationId,
      selfie: selfie,
    };
    setLoading(true);

    try {
      const response = await dispatch(scanProcess({ data }));
      if (response?.payload?.error) {
        toast.error(<span>{response?.payload?.error}</span>);
        setLoading(false);
        Sentry.addBreadcrumb({
          message:
            "Error from Acuant API when sending selfie image (Capture Selfie)",
          category: "action",
          data: {
            acuantTransactionId: instance,
          },
        });
        Sentry.captureException(response?.payload?.error);
      } else {
        setLoading(false);
        setResponsePayload(response?.payload);
        Sentry.addBreadcrumb({
          message: "Image sent to Acuant API (Capture Selfie)",
          category: "action",
          data: { acuantTransactionId: instance },
        });
      }
    } catch (error) {
      setLoading(false);
      Sentry.addBreadcrumb({
        message: "Error processing selfie data (Capture Selfie)",
        category: "action",
        data: { acuantTransactionId: instance },
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (selfie !== null && selfie !== "") {
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfie]);

  return (
    <>
      {loading ? (
        <section className="flex justify-center items-center absolute inset-0 flex-col gap-3 container px-3 py-5 bg-[#F8F7F6]">
          <AnalyzingLoader
            loading={loading}
            text="Please don’t close the browser.
                    This will take a moment."
            responseScan={responseScan}
            tenantColor={tenantColor}
            tenantImage={tenantImage}
            tenantName={tenantName}
          />
        </section>
      ) : (
        <>
          {responsePayload?.ScanProcess?.success ? (
            <Success
              responseScan={responseScan}
              tenantColor={tenantColor}
              tenantImage={tenantImage}
              tenantName={tenantName}
            />
          ) : (
            <section className="flex flex-col gap-2 justify-between">
              <Header
                responseScan={responseScan}
                tenantColor={tenantColor}
                tenantImage={tenantImage}
                tenantName={tenantName}
              />
              <div className="">
                <h2 className="text-2xl text-center my-5 mb-6">
                  Take a selfie
                </h2>
                <p className="text-sm text-center m-0 p-0">
                  For the best scan results
                </p>
              </div>

              <section className="bg-white border rounded-lg border-[#DADADA] px-4 py-5">
                <div className="flex justify-start items-center gap-3 mb-3">
                  {/* Icon Sun*/}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      r="8"
                      fill="white"
                      stroke={tenantColor}
                      strokeWidth="2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21 3H19V9H21V3ZM21 31H19V37H21V31ZM31.3137 7.27222L32.7279 8.68643L28.4853 12.9291L27.071 11.5149L31.3137 7.27222ZM12.9289 28.4853L11.5147 27.071L7.27207 31.3137L8.68628 32.7279L12.9289 28.4853ZM37 19V21H31V19H37ZM9 21V19H3V21H9ZM32.7279 31.3137L31.3137 32.7279L27.0711 28.4853L28.4853 27.0711L32.7279 31.3137ZM11.5147 12.9291L12.9289 11.5149L8.68628 7.27225L7.27207 8.68647L11.5147 12.9291Z"
                      fill={tenantColor}
                    />
                  </svg>

                  <p className="text-sm ">Find an area with good lighting</p>
                </div>
                <div className="flex justify-start items-center gap-3 mb-3">
                  {/* Icon Eye*/}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.6042 18.5043C36.3329 19.3891 36.3329 20.6109 35.6042 21.4957C34.2139 23.1836 31.9999 25.58 29.2657 27.5471C26.5237 29.5199 23.3437 31 20 31C16.6563 31 13.4763 29.5199 10.7343 27.5471C8.0001 25.58 5.78609 23.1836 4.39582 21.4957C3.66707 20.6109 3.66707 19.3891 4.39582 18.5043C5.78609 16.8164 8.0001 14.42 10.7343 12.4529C13.4763 10.4801 16.6563 9 20 9C23.3437 9 26.5237 10.4801 29.2657 12.4529C31.9999 14.42 34.2139 16.8164 35.6042 18.5043Z"
                      fill="white"
                      stroke={tenantColor}
                      strokeWidth="2"
                    />
                    <circle
                      cx="20"
                      cy="20"
                      r="5"
                      fill="white"
                      stroke={tenantColor}
                      strokeWidth="2"
                    />
                  </svg>

                  <p className="text-sm">
                    {" "}
                    Make sure your camera is at eye level
                  </p>
                </div>
              </section>

              <div className="flex flex-col gap-4 w-full items-center justify-center">
                <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                  <button
                    className={`shadow-md `}
                    style={{
                      backgroundColor: tenantColor,
                    }}
                    onClick={() => openFrontCamara()}
                  >
                    Take a selfie <BiSolidFace size={20} />
                  </button>
                </div>
              </div>
            </section>
          )}
          <div>
            <div
              id="acuant-face-capture-container"
              style={{
                height: "custom-height",
                width: "custom-width",
              }}
            >
              <div id="face-detection-text"></div>
            </div>
          </div>
        </>
      )}

      <Toaster
        position={ALERTS_POSITION_SELFIE}
        reverseOrder={false}
        toastOptions={ALERTS_STYLES_SELFIE}
      />
    </>
  );
};

export default CaptureSelfie;
